import React from "react";


export default function Play(props) {
  const { handleClick } = props;
  
  return (
    <button className="btn btn-dark btn-play-btn" onClick={() => handleClick()}>
    <i className="fas fa-pause"></i>
    </button>
  );
}
