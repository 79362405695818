import React from 'react'
import {Link} from 'react-router-dom'
import SideNav, {NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import Search from '../../assets/Search.png';
import Home from '../../assets/Home.png';
import Warehouse from '../../assets/Warehouse.png';
import Resources from '../../assets/Resources.png';
import Security from '../../assets/Security.png';
import Faq from '../../assets/FAQ.png';
import Support from '../../assets/support.png'


export const SideNavBar = ({ currentPage }) => {
    return (
        <SideNav
    onSelect={(selected) => {
        // Add your code here
    }}
>
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected={currentPage}>
    <NavItem eventKey="search">
    <NavIcon>
        <img src={Search} alt="" className="sidebarIcon" />
    </NavIcon>
    <NavText>
        Search
    </NavText>
</NavItem>
        <NavItem eventKey="home">
            <NavIcon>
            <Link to="/home"><img src={Home} alt="" className="sidebarIcon"  /></Link>
            </NavIcon>
            <NavText>
                <Link to="/home">Home</Link>
            </NavText>
        </NavItem>

        <NavItem eventKey="warehouse">
        <NavIcon>
        <Link to="/memowarehouse"><img src={Warehouse} alt="" className="sidebarIcon"  /></Link>
        </NavIcon>
        <NavText>
        <Link to="/memowarehouse">Memo Warehouse</Link>
        </NavText>
    </NavItem>
  

    <NavItem eventKey="resourses">
    <NavIcon>
    <Link to="/resources"><img src={Resources} alt="" className="sidebarIcon"  /></Link>
    </NavIcon>
    <NavText>
    <Link to="/resources">Resources</Link>
    </NavText>
</NavItem>

<NavItem eventKey="privacysecurity">
<NavIcon>
<Link to="/privacyandsecurity"><img src={Security} alt="" className="sidebarIcon"  /></Link>
</NavIcon>
<NavText>
<Link to="/privacyandsecurity">Privacy & Security</Link>
</NavText>
</NavItem>

<NavItem eventKey="faq">
    <NavIcon>
    <Link to="/faq"><img src={Faq} alt="" className="sidebarIcon"  /></Link>
    </NavIcon>
    <NavText>
    <Link to="/faq">FAQ</Link>
    </NavText>
</NavItem>

<NavItem eventKey="support">
    <NavIcon>
    <Link to="/support"><img src={Support} alt="" className="sidebarIcon"  /></Link>
    </NavIcon>
    <NavText>
    <Link to="/support">Support</Link>
    </NavText>
</NavItem>
       
       
    </SideNav.Nav>
</SideNav>
    )
}
