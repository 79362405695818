import React from 'react';
import LOGO from '../../assets/Memosight-logo.png';

export const Footer = () => {
    var d = new Date();
    var year = d.getFullYear();
    return (
        <div className="col-md-12 footer mt-1 mb-3">
           <div className="row">
              <hr className="footer_hr"/>
              <div className="col-md-6 col-sm-12 copyright_desktop" >&copy; Memosight.com {year}</div>
              <div className="col-md-6 col-sm-12 footer_logo" ><img src={LOGO} alt="Memosight" /></div>
              <div className="col-md-6 col-sm-12 copyright_mobile" >&copy; Memosight.com {year}</div>
           </div>
        </div>
    )
}
