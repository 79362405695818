import React from "react";

import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";

import useAudioPlayer from "./useAudioPlayer";

function Audio({song}) {
  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime
  } = useAudioPlayer();

  return (
    <div>
      <audio id="audio2">
        <source src={song} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="player">
      <div className="row controls ">
       
           <div className="col-2">
           {playing ? (
            <Pause handleClick={() => setPlaying(false)} />
          ) : (
            <Play handleClick={() => setPlaying(true)} />
          )}
           </div>
           <div className="col-10">
           <Bar
           curTime={curTime}
           duration={duration}
           onTimeUpdate={(time) => setClickedTime(time)}
         />
           </div>
        </div>
        </div>
       
     
    </div>
  );
}

export default Audio;
