import React, {useState} from "react";


import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';
import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";
import { MobileMenu } from "../common/MobileMenu";
import Like from "../../assets/like.png";
import Dislike from "../../assets/dislike.png";
import Memo from "../../assets/memo.png";
import Favourite from "../../assets/favourite.png";
import LikeSelected from "../../assets/like_selected.png";
import DislikeSelected from "../../assets/dislike_selected.png";
import FavouriteSelected from "../../assets/favourite_selected.png";
import Fullscreen from "../../assets/fullscreen.png";
import Slider from './Slider';
import MobileSlider from './MobileSlider';




export const Insight = () => {
  const [thumbsup, setThumbsup] = useState(false);
  const [thumbsdown, setThumbsdown] = useState(false);
  const [favouriteSelected, setFavouriteSelected] = useState(false);

  const onClickThumbsUp = () => {
    setThumbsup(!thumbsup);
    setThumbsdown(false);
  }

  const onClickThumbsDown = () => {
    setThumbsup(false);
    setThumbsdown(!thumbsdown);
  }

  const onClickFavourite = () => {
    setFavouriteSelected(!favouriteSelected)
  }

  const handle = useFullScreenHandle();

  let slideshow;

  let menubar;

  if (isMobile) {
    slideshow = <MobileSlider />
    menubar = <Menu width={200 } customBurgerIcon={ <i className="fas fa-bars"></i> }>
       <MobileMenu />
    </Menu>
  } else {
    slideshow = <Slider />
    menubar = <SideNavBar />
  }

  return (
    <div className="container-fluid">
    {menubar}
      <div className="row">
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Add mNote</h5>
            <button type="button" className="btn-close btn-dark" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label for="recipient-name" className="col-form-label">User:</label>
                <input type="text" className="form-control" id="recipient-name" value="john@demo.com" />
              </div>
              <div className="mb-3">
                <label for="message-text" className="col-form-label">Write mNote:</label>
                <textarea className="form-control" id="message-text"></textarea>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Submit mNote</button>
          </div>
        </div>
      </div>
    </div>
        <div className="col-12 main-container">
          <Header />
          <div className="row mt-5 ">
            <div className="col-md-6 col-sm-12 col-lg-6">
              <h3 className="insight-memo-title">Instagram had the highest engagement at 18% !</h3>
            </div>
            <div className="col-md-6 col-sm-12 insightIconsWrapper">
            <img src={Memo} alt="" className="memosight-Icons" data-bs-toggle="modal" data-bs-target="#exampleModal"  />
            <img src={thumbsup?LikeSelected:Like} alt="" className="memosight-Icons" onClick={onClickThumbsUp} />
            <img src={thumbsdown?DislikeSelected:Dislike} alt="" className="memosight-Icons" onClick={onClickThumbsDown} />
            <img src={favouriteSelected?FavouriteSelected:Favourite} alt="" className="memosight-Icons" onClick={onClickFavourite} />
            <img src={Fullscreen} alt="" className="memosight-Icons" onClick={handle.enter} />  
           
            </div>
            <div className="col-md-12 mt-3 ">
              <FullScreen handle={handle}>
                  {slideshow}
              </FullScreen>
            </div>

            <div className="col-md-12 col-sm-12 mobileInsightIconsWrapper mt-3 mb-5 text-center">
            <img src={Memo} alt="" className="memosight-Icons" data-bs-toggle="modal" data-bs-target="#exampleModal"  />
            <img src={thumbsup?LikeSelected:Like} alt="" className="memosight-Icons" onClick={onClickThumbsUp} />
            <img src={thumbsdown?DislikeSelected:Dislike} alt="" className="memosight-Icons" onClick={onClickThumbsDown} />
            <img src={favouriteSelected?FavouriteSelected:Favourite} alt="" className="memosight-Icons" onClick={onClickFavourite} />
            <img src={Fullscreen} alt="" className="memosight-Icons" onClick={handle.enter} />  
            </div>
            
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
