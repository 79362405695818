import React, {useState} from "react";
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';
import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";
import { MobileMenu } from "../common/MobileMenu";
import { LatestMemo } from "./LatestMemo";
import { SideAudio } from "./SideAudio";
import { SideAudio2 } from "./SideAudio2";
import { SideAudio3 } from "./SideAudio3";
import WEEK1MP3 from "../../assets/audios/Week2.mp3";
import WEEK2MP3 from "../../assets/audios/Week3.mp3";
import WEEK3MP3 from "../../assets/audios/Week4.mp3";

export const Home = () => {
  const [loadMemo, setLoadMemo] = useState(false);

  let menubar;
  let loadmore; 
  let loadless;
  let sideAudios;


  const LoadMemoAudios = () => {
    setLoadMemo(true);
  }

  const LoadLessMemoAudios = () => {
    setLoadMemo(false);
  }

  if (isMobile) {
    
    menubar = (<Menu width={200 } customBurgerIcon={ <i className="fas fa-bars"></i> }>
       <MobileMenu />
    </Menu>)

    loadmore = (
      <div className="col-sm-12 text-center mt-3">
         <button type="button" className="btn btn-sm btn-primary">Load more...</button>
      </div>
    )

    if (loadMemo) {
      sideAudios = (<div className="col-md-6 col-sm-12">
    <SideAudio
      title="Rock stars are display campaigns !"
      date="14th Sep, 2020"
      mp3={WEEK1MP3}
    />

    <SideAudio2
      title="Social media boosted the traffic !"
      date="21st Sep ,2020"
      mp3={WEEK2MP3}
    />

    <SideAudio3
      title="Organic traffic performed well !"
      date="28th Sep, 2020"
      mp3={WEEK3MP3}

    />
  </div>);

  loadmore = ""
  loadless =  (
    <div className="col-sm-12 text-center mt-3">
       <button type="button" className="btn btn-sm btn-primary btn-load" onClick={LoadLessMemoAudios}>VIEW LESS</button>
    </div>
  )

    } else {
      sideAudios ="";
      loadless = ""
      loadmore = (
        <div className="col-sm-12 text-center mt-3">
           <button type="button" className="btn btn-sm btn-primary btn-load" onClick={LoadMemoAudios}>LOAD MORE</button>
        </div>
      )
    }
   
 
  } else {
   
    menubar = <SideNavBar currentPage="home" />
    loadmore = "";
    loadless = ""
    sideAudios = (<div className="col-md-6 col-sm-12">
    <SideAudio
      title="Rock stars are display campaigns !"
      date="14th Sep, 2020"
      mp3={WEEK1MP3}
    />

    <SideAudio2
      title="Social media boosted the traffic !"
      date="21st Sep ,2020"
      mp3={WEEK2MP3}
    />

    <SideAudio3
      title="Organic traffic performed well !"
      date="28th Sep, 2020"
      mp3={WEEK3MP3}

    />
  </div>);
  }


  return (
    <div className="container-fluid">
    {menubar}
   
      <div className="row">
        
        <div className="col-md-12  main-container">
          <Header />
          <div className="row">
            <div className="col-md-12 mt-3 ">
              <div className="row mb-2">
                <div className="col-md-6 col-sm-12 latest-memo-wrapper">
                  
                  <LatestMemo />
                </div>
                {loadmore}
                {sideAudios}
                {loadless}
                
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
