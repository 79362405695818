import React from 'react';
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import InsightIcon from '../../assets/Insights-icon.png';
import Fullscreen from "../../assets/fullscreen.png";
import Audio from '../audioPlayer/audio2/Audio';

export const MemoAudio2 = ({title,date, mp3 }) => {
  const handle = useFullScreenHandle();
  let sideAudio;

  if (isMobile) {
    sideAudio = (
      <div className="row">
        <div className="col-12 ">
          <div className="row">
            <div className="col-8">
              <h5 className="audio-title2">{title}</h5>
            </div>
            <div className="col-4 date-align">
              <span className="audio-date">{date}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-8 mt-2">
              <FullScreen handle={handle}>
                <Audio song={mp3} />
              </FullScreen>
            </div>
            <div className="col-2 sideAudioFullScreen">
              <img
                src={Fullscreen}
                alt=""
                className="memosight-Icons"
                onClick={handle.enter}
              />
            </div>
            <div className="col-2   text-center">
              <Link to="/insight">
                <img
                  src={InsightIcon}
                  alt="Memosight"
                  className="insight-icon img-fluid"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    sideAudio = (
      <div className="row">
        <div className="col-10 ">
          <div className="row">
            <div className="col-8">
              <h5 className="audio-title2">{title}</h5>
            </div>
            <div className="col-4 date-align">
              <span className="audio-date">{date}</span>
            </div>

            <div className="col-9 mt-3">
              <FullScreen handle={handle}>
                <Audio song={mp3} />
              </FullScreen>
            </div>
            <div className="col-3 sideAudioFullScreen">
              <img
                src={Fullscreen}
                alt=""
                className="memosight-Icons"
                onClick={handle.enter}
              />
            </div>
          </div>
        </div>
        <div className="col-2   text-center">
          <Link to="/insight">
            <img
              src={InsightIcon}
              alt="Memosight"
              className="insight-icon img-fluid"
            />
          </Link>
        </div>
      </div>
    );
  }

  return <div className="sideAudioWrapper">{sideAudio}</div>;
}
