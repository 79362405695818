import React from 'react'
import {Link} from 'react-router-dom'

import Search from '../../assets/Search.png';
import Home from '../../assets/Home.png';
import Warehouse from '../../assets/Warehouse.png';
import Resources from '../../assets/Resources.png';
import Security from '../../assets/Security.png';
import Faq from '../../assets/FAQ.png';
import Support from '../../assets/support.png';


export const MobileMenu = () => {
    return (

        <div>
        <Link to="/"  className="mobileNav"><img src={Search} alt="" className="sidebarIcon"  /> Search</Link><br/><br/>
        <Link to="/home"  className="mobileNav"><img src={Home} alt="" className="sidebarIcon"  /> Home</Link><br/><br/>
        <Link to="/memowarehouse" className="mobileNav"><img src={Warehouse} alt="" className="sidebarIcon"  /> Memo Warehouse</Link><br/><br/>
        <Link to="/resources" className="mobileNav"><img src={Resources} alt="" className="sidebarIcon"  /> Resources</Link><br/><br/>
        <Link to="/privacyandsecurity" className="mobileNav"><img src={Security} alt="" className="sidebarIcon"  /> Privacy & Security</Link><br /><br/>
        <Link to="/faq" className="mobileNav"><img src={Faq} alt="" className="sidebarIcon"  /> FAQ</Link><br/><br/>
        <Link to="/support" className="mobileNav"><img src={Support} alt="" className="sidebarIcon"  /> Support</Link>
        </div>
       
    )
}
