import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import MobileInsight1 from "../../assets/insights/insightMobile1.jpg";
import MobileInsight2 from "../../assets/insights/insightMobile2.jpg";
import MobileInsight3 from "../../assets/insights/insightMobile3.jpg";
import MobileInsight4 from "../../assets/insights/insightMobile4.jpg";
import MobileInsight5 from "../../assets/insights/insightMobile5.jpg";
import MobileInsight6 from "../../assets/insights/insightMobile6.jpg";
import MobileInsight7 from "../../assets/insights/insightMobile7.jpg";

const items = [
  {
    src: MobileInsight1 ,
    altText: '',
    caption: ''
  },
  {
    src: MobileInsight2 ,
    altText: '',
    caption: ''
  },
  {
    src: MobileInsight3 ,
    altText: '',
    caption: ''
  },
  {
    src: MobileInsight4 ,
    altText: '',
    caption: ''
  },
  {
    src: MobileInsight5 ,
    altText: '',
    caption: ''
  },
  {
    src: MobileInsight6 ,
    altText: '',
    caption: ''
  },
  {
    src: MobileInsight7 ,
    altText: '',
    caption: ''
  }
];

const MobileSlider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="img-fluid" />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={ false }
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default MobileSlider;