import React from "react";
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';
import { MobileMenu } from "../common/MobileMenu";

import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";
import SecurityActive from "../../assets/Security_active.png";
import DataP from "../../assets/DataP.png";
import Userp from "../../assets/Userp.png";
import Storage from "../../assets/Storge.png";

export const Privacy = () => {
  
  let menubar;
 

  if (isMobile) {
    
    menubar = <Menu width={200 } customBurgerIcon={ <i className="fas fa-bars"></i> }>
       <MobileMenu />
    </Menu>
  
 
  } else {
   
    menubar = <SideNavBar currentPage="privacysecurity" />
  }


   

  return (
    <div className="container-fluid">
    {menubar}
      <div className="row">
       
        <div className="col-md-12 main-container">
          <Header />
          <div className="row mt-5 ">
            <div className="col-md-6">
              <h3 className="insight-memo-title"><img src={SecurityActive} alt="" /> Privacy & Security</h3>
            </div>
            <div className="col-md-6 insightIconsWrapper">
              
            </div>
            <div className="col-md-12 mt-3 profile_wrapper p-5">
               
             <div className="row">
               <div className="col-md-12 text-center mb-5">
                <h4><strong>We are committed to Data security</strong></h4>
               </div>
               <div className="col-md-4 col-sm-12 text-center">
                 <img src={DataP} alt="" className="mb-2" />
                 <h4 className="mb-2">Data Protection</h4>
                 <p className="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae metus a augue rhoncus porta. Curabitur ornare nisl et vehicula mollis. Donec malesuada est commodo tristique posuere. Curabitur ultricies ex eu lectus pretium posuere. Cras rhoncus dolor eu accumsan venenatis. Morbi ac dapibus arcu, ac aliquet justo. Aenean ante quam, lacinia sed eleifend vitae, porttitor in metus. Praesent eget ornare lorem. Phasellus eget mattis lacus. Cras nisl sem, semper eu leo non, fermentum volutpat quam. Nullam in euismod lectus.</p>
               </div>
               <div className="col-md-4 col-sm-12 text-center">
               <img src={Userp} alt="" className="mb-2" />
               <h4 className="mb-2">User Protection</h4>
               <p className="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae metus a augue rhoncus porta. Curabitur ornare nisl et vehicula mollis. Donec malesuada est commodo tristique posuere. Curabitur ultricies ex eu lectus pretium posuere. Cras rhoncus dolor eu accumsan venenatis. Morbi ac dapibus arcu, ac aliquet justo. Aenean ante quam, lacinia sed eleifend vitae, porttitor in metus. Praesent eget ornare lorem. Phasellus eget mattis lacus. Cras nisl sem, semper eu leo non, fermentum volutpat quam. Nullam in euismod lectus.</p>
             </div>
             <div className="col-md-4 col-sm-12 text-center">
             <img src={Storage} alt="" className="mb-2" />
             <h4 className="mb-2">Storage of Data</h4>
             <p className="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae metus a augue rhoncus porta. Curabitur ornare nisl et vehicula mollis. Donec malesuada est commodo tristique posuere. Curabitur ultricies ex eu lectus pretium posuere. Cras rhoncus dolor eu accumsan venenatis. Morbi ac dapibus arcu, ac aliquet justo. Aenean ante quam, lacinia sed eleifend vitae, porttitor in metus. Praesent eget ornare lorem. Phasellus eget mattis lacus. Cras nisl sem, semper eu leo non, fermentum volutpat quam. Nullam in euismod lectus.</p>
           </div>
             
             </div>
             
           
       
              
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
