import React from "react";
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';
import { MobileMenu } from "../common/MobileMenu";
import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";
import FaqActive from "../../assets/FAQ_active.png";

export const Faq = () => {
  
   
  let menubar;
 

  if (isMobile) {
    
    menubar = <Menu width={200 } customBurgerIcon={ <i className="fas fa-bars"></i> }>
       <MobileMenu />
    </Menu>
  
 
  } else {
   
    menubar = <SideNavBar currentPage="faq" />
  }

   

  return (
    <div className="container-fluid">
    {menubar}
      <div className="row">
       
        <div className="col-md-12 main-container">
          <Header />
          <div className="row mt-5 ">
            <div className="col-md-6">
              <h3 className="insight-memo-title"><img src={FaqActive} alt="" /> Faq</h3>
            </div>
            <div className="col-md-6 insightIconsWrapper">
              
            </div>
            <div className="col-md-12 mt-3 mb-5">
            <div className="accordion" id="accordionExample">
            <div className="accordion-item profile_wrapper">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What are the components of Memosight ?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>From a perspective of delivery, Memosight has 4 major components. They are :</p>
                 
                  <ul>
                    <li>Memo : The unit delivered to a user at regular intervals. A memo consists of two elements , mAudio and mSight.</li>
                    <li>mAudio: An audio report within a memo.</li>
                    <li>mSight: Set of screens carrying insights and data visuals. Each screen is called mCard. A bundle of mCards form a mSight.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item profile_wrapper mt-3">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  How can i provide feedback to Memos ?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>You have multiple options to provide feedback.</p>
                  <ul>
                    <li>Option 1: Each memo has a mNote icon, which can be used to provide feedback for that particular memo. </li>
                    <li>Option 2: You can also record your immediate reaction by making use of the like/dislike button below every memo. These reaction buttons are also available at a mCard level as well. </li>
                    <li>Option 3: Use the “Send Feedback” option under the left panel to record your generic feedback or suggestions on memos.</li>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
            
            
       
              
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
