import React from 'react';
import { Link } from "react-router-dom";

import InsightIcon from '../../assets/Insights-icon.png';


export const MemoSight = ({title,date,callFunction}) => {

  
  
    return (
      <div className="sideAudioWrapper">
      <div className="row">
        <div className="col-9 ">
          <div className="row">
          <div className="col-4 ">
          <span className="mCard_count">6 mCards</span>
          </div>
          <div className="col-8 date-align">
          <span className="audio-date">{date}</span>
        </div>
            <div className="col-12 mt-3">
              <h5 className="audio-title-mobile">{title}</h5>
            </div>
            

           
           
            
          </div>
        </div>
        <div className="col-3   text-center">
          <Link to="/insight">
            <img src={InsightIcon} alt="Memosight" className="insight-icon img-fluid" />
          </Link>
        </div>
        <div className="col-12 plus-align">
                  <button className="btn-plus-mSight" onClick={callFunction}><i className="fas fa-plus"></i></button>
        </div>
      </div>

    </div>
    )
}
