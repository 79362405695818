import React from "react";
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';
import { MobileMenu } from "../common/MobileMenu";

import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";

import SupportActive from "../../assets/support_active.png";


export const Support = () => {


  
  let menubar;
 

  if (isMobile) {
    
    menubar = <Menu width={200 } customBurgerIcon={ <i className="fas fa-bars"></i> }>
       <MobileMenu />
    </Menu>
  
 
  } else {
   
    menubar = <SideNavBar currentPage="support" />
  }


  

   

  return (
    <div className="container-fluid">
    {menubar}
      <div className="row">
       
        <div className="col-md-12 main-container">
          <Header />
          <div className="row mt-5 ">
            <div className="col-md-6">
              <h3 className="insight-memo-title"><img src={SupportActive} alt="" /> Support</h3>
            </div>
            <div className="col-md-6 insightIconsWrapper">
              
            </div>
            <div className="col-md-12 mt-3 mb-5 profile_wrapper">
             <div className="row mt-5 mb-5">
                <div className="col-md-4 col-sm-12"></div>
                <div className="col-md-4 col-sm-12 text-center">
                <input
                        type="text"
                        name="email"
                        value="john@demo.com"
                        className="form-control profileInput"
                      />
                      <br/>
                <select
                name="category"
                className="form-control dropdown_style2"
                placeholder="Support Category"
                
              >
              
              <option value="">Support Category</option>
                <option value="data-accuracy">Data Accuracy</option>
                <option value="suggest-memo">Suggest Memo</option>
                <option value="platform">Platform</option>
                <option value="Others">Others</option>
              </select><br/>
              <textarea
                 name="message"
                 className="form-control profileInput"
                 placeholder="Type here..."
                 /><br/>
                 <button
                    type="button"
                    className="btn btn-block btn-primary "
                  >
                    SUBMIT
                  </button>
                </div>
                <div className="col-md-4 col-sm-12"></div>
             </div>
           
       
              
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
