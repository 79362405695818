import React, { useState } from "react";
import { MAudio } from "./MAudio";
import { MSight } from "./MSight";
import Like from "../../assets/like.png";
import Dislike from "../../assets/dislike.png";
import Memo from "../../assets/memo.png";
import Favourite from "../../assets/favourite.png";
import LikeSelected from "../../assets/like_selected.png";
import DislikeSelected from "../../assets/dislike_selected.png";
import FavouriteSelected from "../../assets/favourite_selected.png";





export const LatestMemo = () => {
  const [thumbsup, setThumbsup] = useState(false);
  const [thumbsdown, setThumbsdown] = useState(false);
  const [favouriteSelected, setFavouriteSelected] = useState(false);

  const onClickThumbsUp = () => {
    setThumbsup(!thumbsup);
    setThumbsdown(false);
  }

  const onClickThumbsDown = () => {
    setThumbsup(false);
    setThumbsdown(!thumbsdown);
  }

  const onClickFavourite = () => {
    setFavouriteSelected(!favouriteSelected)
  }

 

  

  return (
    <div className="LatestMemoStyle">
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Add mNote</h5>
            <button type="button" className="btn-close btn-dark" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label for="recipient-name" className="col-form-label">User:</label>
                <input type="text" className="form-control" id="recipient-name" value="john@demo.com" />
              </div>
              <div className="mb-3">
                <label for="message-text" className="col-form-label">Write mNote:</label>
                <textarea className="form-control" id="message-text"></textarea>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Submit mNote</button>
          </div>
        </div>
      </div>
    </div>
      <h5 className="latest-memo-date">5th Oct, 2020</h5>
      <h3 className="latest-memo-title2">
        Instagram had the highest engagement at 18% !
      </h3>
      <MAudio />
      <br />
      <MSight />
      <div className="row mt-3">
        <div className="col-md-12 text-right memoIconsWrapper">
         <img src={Memo} alt="" className="memosight-Icons" data-bs-toggle="modal" data-bs-target="#exampleModal"  />
         <img src={thumbsup?LikeSelected:Like} alt="" className="memosight-Icons" onClick={onClickThumbsUp} />
         <img src={thumbsdown?DislikeSelected:Dislike} alt="" className="memosight-Icons" onClick={onClickThumbsDown} />
         <img src={favouriteSelected?FavouriteSelected:Favourite} alt="" className="memosight-Icons" onClick={onClickFavourite} />
        </div>
      </div>
    </div>
  );
};
