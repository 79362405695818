import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import { slide as Menu } from "react-burger-menu";
import "react-datepicker/dist/react-datepicker.css";
import { MobileMenu } from "../common/MobileMenu";
import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";
import SimpleReactLightbox from 'simple-react-lightbox';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { MemoAudio } from "./MemoAudio";
import { MemoAudio2 } from "./MemoAudio2";
import { MemoAudio3 } from "./MemoAudio3";
import { MemoAudio4 } from "./MemoAudio4";
import { MemoAudio5 } from "./MemoAudio5";
import { MemoAudio6 } from "./MemoAudio6";
import { MemoSight } from "./MemoSight";
import { MemoCard } from "./MemoCard";
import WEEK1MP3 from "../../assets/audios/Week2.mp3";
import WEEK2MP3 from "../../assets/audios/Week3.mp3";
import WEEK3MP3 from "../../assets/audios/Week4.mp3";

import MCARD1 from "../../assets/mCard/insight1.png";
import MCARD2 from "../../assets/mCard/insight2.png";
import MCARD3 from "../../assets/mCard/insight3.png";
import MCARD4 from "../../assets/mCard/insight4.png";
import MCARD5 from "../../assets/mCard/insight5.png";
import MCARD6 from "../../assets/mCard/insight6.png";
import MCARD7 from "../../assets/mCard/insight7.png";


import Insight1 from "../../assets/insights/insight1.jpg";
import Insight2 from "../../assets/insights/insight2.jpg";
import Insight3 from "../../assets/insights/insight3.jpg";
import Insight4 from "../../assets/insights/insight4.jpg";
import Insight5 from "../../assets/insights/insight5.jpg";
import Insight6 from "../../assets/insights/insight6.jpg";
import Insight7 from "../../assets/insights/insight7.jpg";

import MobileInsight1 from "../../assets/insights/insightMobile1.jpg";
import MobileInsight2 from "../../assets/insights/insightMobile2.jpg";
import MobileInsight3 from "../../assets/insights/insightMobile3.jpg";
import MobileInsight4 from "../../assets/insights/insightMobile4.jpg";
import MobileInsight5 from "../../assets/insights/insightMobile5.jpg";
import MobileInsight6 from "../../assets/insights/insightMobile6.jpg";
import MobileInsight7 from "../../assets/insights/insightMobile7.jpg";

import Thumb1 from "../../assets/Insight1.png";
import Thumb2 from "../../assets/insight2.png";
import InsightIcon from "../../assets/mDeck_icon.png";

export const MemoWareHousing = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [memoOption, setMemoOption] = useState("Memo");
  const [bottomBar, setBottomBar] = useState(false);
  const [bottomBarMin, setBottomBarMin] = useState(false);
  const [visibleDeck, setVisibleDeck] = useState(false);
  const [visibleThumb1, setVisibleThumb1] = useState(false);
  const [visibleThumb2, setVisibleThumb2] = useState(false);

  const {
   
    className
  } = props;


  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);



  let menubar;

  if (isMobile) {
    menubar = (
      <Menu width={200} customBurgerIcon={<i className="fas fa-bars"></i>}>
        <MobileMenu />
      </Menu>
    );
  } else {
    menubar = <SideNavBar currentPage="warehouse" />;
  }

  let memoOptionWrapper;
  let memoDropdown;

  const ParentFunction = () => {
    console.log("Running Parent function");
    setVisibleDeck(true);
  };

  const VisibleThumb1 = () => {
    setVisibleThumb1(true);
  };

  const VisibleThumb2 = () => {
    setVisibleThumb2(true);
  };

  const bottomBarMinimize = () => {
    setBottomBar(false);
    setBottomBarMin(true);
  };

  const bottomBarMaximize = () => {
    setBottomBar(true);
    setBottomBarMin(false);
  };

  const onSelectMemoOption = (e) => {
    setMemoOption(e.target.value);

    if (e.target.value === "Memo") {
      setBottomBar(false);
      setBottomBarMin(false);
    } else {
      setBottomBar(true);
      setBottomBarMin(false);
    }
  };

  let bottomDiv = (
    <div className="bottom_div">
      <div className="row">
        <div className="col-md-12 col-sm-12 close_btn">
          <button className="btn-plus-mSight" onClick={bottomBarMinimize}>
            <i className="fas fa-window-minimize"></i>
          </button>
        </div>
        <div className="col-md-8 col-sm-12">
          <div className="row">
            <div className="col-md-2  text-center">
              <img src={Thumb1} alt="Memosight" className="img-fluid" />
            </div>
            <div className="col-md-2  text-center">
              <img src={InsightIcon} alt="Memosight" className="img-fluid" />
              <span className="mCard_count">6 Cards</span>
            </div>
            {visibleThumb1 ? (
              <div className="col-md-2  text-center">
                <img src={Thumb2} alt="Memosight" className="img-fluid" />
              </div>
            ) : (
              ""
            )}
            {visibleThumb2 ? (
              <div className="col-md-2  text-center">
                <img src={Thumb1} alt="Memosight" className="img-fluid" />
              </div>
            ) : (
              ""
            )}
            {visibleDeck ? (
              <div className="col-md-2  text-center">
                <img src={InsightIcon} alt="Memosight" className="img-fluid" />
                <span className="mCard_count">6 Cards</span>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-2 col-sm-12"></div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 text-center mt-2">
          <h6>15 Slides to be Generated</h6>
          <button className="btn btn-light btn-block generate_deck" onClick={toggle}>
            <i className="fas fa-cloud-download-alt"></i> Generate mDeck
          </button>
        </div>
      </div>
    </div>
  );

  let bottomDivMin = (
    <div className="bottom-div-min">
      <button className="btn btn-dark btn_insight" onClick={bottomBarMaximize}>
        <i className="far fa-window-maximize"></i>
      </button>
    </div>
  );


  let deckCartMobile;

  if (memoOption === "Memo") {
    console.log("Selected Memo");

    memoOptionWrapper = (
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <MemoAudio
                title="Rock stars are display campaigns !"
                date="14th Sep, 2020"
                mp3={WEEK1MP3}
              />
              <MemoAudio2
                title="Social media boosted the traffic !"
                date="21st Sep, 2020"
                mp3={WEEK2MP3}
              />
              <MemoAudio3
                title="Organic traffic performed well !"
                date="28th Sep, 2020"
                mp3={WEEK3MP3}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <MemoAudio4
                title="Rock stars are display campaigns !"
                date="14th Sep, 2020"
                mp3={WEEK1MP3}
              />
              <MemoAudio5
                title="Social media boosted the traffic !"
                date="21st Sep, 2020"
                mp3={WEEK2MP3}
              />
              <MemoAudio6
                title="Organic traffic performed well !"
                date="28th Sep, 2020"
                mp3={WEEK3MP3}
              />
            </div>
          </div>
        </div>
      </div>
    );

    if (isMobile) {
      memoDropdown = (
        <div className="row mt-5 ">
          <div className="col-9">
            <span className="insight-memo-title">Memo Warehouse</span>
          </div>
          <div className="col-3 text-right">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
              />
              <label className="form-check-label" for="flexSwitchCheckDefault">
                <i className="fas fa-star nav_icons"></i>
              </label>
            </div>
          </div>
          <div className="col-12 memoWarehouse">
            <div className="row">
              <div className="col-6">
                <select
                  name="memo"
                  className="form-control dropdown_style"
                  onChange={onSelectMemoOption}
                >
                  <option value="Memo">Memo</option>
                  <option value="mSight">mSight</option>
                  <option value="mCards">mCards</option>
                </select>
              </div>

              <div className="col-6">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control dropdown_style"
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      memoDropdown = (
        <div className="row mt-5 ">
          <div className="col-md-4">
            <h3 className="insight-memo-title">Memo Warehouse</h3>
          </div>
          <div className="col-md-8 memoWarehouse">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-3">
                <select
                  name="memo"
                  className="form-control dropdown_style"
                  onChange={onSelectMemoOption}
                >
                  <option value="Memo">Memo</option>
                  <option value="mSight">mSight</option>
                  <option value="mCards">mCards</option>
                </select>
              </div>
              <div className="col-3">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control dropdown_style"
                />
              </div>

              <div className="col-1">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <i className="fas fa-star nav_icons"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    deckCartMobile="";

  } else if (memoOption === "mSight") {
    console.log("Selected mSight");

    memoOptionWrapper = (
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12">
              <MemoSight
                title="Rock stars are display campaigns !"
                date="Week of September, 14"
                callFunction={ParentFunction}
              />
              <MemoSight
                title="Social media boosted the traffic !"
                date="Week of September, 21"
                callFunction={ParentFunction}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12">
              <MemoSight
                title="Rock stars are display campaigns !"
                date="Week of September, 14"
                callFunction={ParentFunction}
              />
              <MemoSight
                title="Social media boosted the traffic !"
                date="Week of September, 21"
                callFunction={ParentFunction}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12">
              <MemoSight
                title="Rock stars are display campaigns !"
                date="Week of September, 14"
                callFunction={ParentFunction}
              />
              <MemoSight
                title="Social media boosted the traffic !"
                date="Week of September, 21"
                callFunction={ParentFunction}
              />
            </div>
          </div>
        </div>
      </div>
    );

    if (isMobile) {
      memoDropdown = (
        <div className="row mt-5 ">
          <div className="col-9">
            <span className="insight-memo-title">Memo Warehouse</span>
          </div>
          <div className="col-3 text-right">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
              />
              <label className="form-check-label" for="flexSwitchCheckDefault">
                <i className="fas fa-star nav_icons"></i>
              </label>
            </div>
          </div>
          <div className="col-12 memoWarehouse">
            <div className="row">
              <div className="col-6">
                <select
                  name="memo"
                  className="form-control dropdown_style"
                  onChange={onSelectMemoOption}
                >
                  <option value="Memo">Memo</option>
                  <option value="mSight">mSight</option>
                  <option value="mCards">mCards</option>
                </select>
              </div>

              <div className="col-6">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control dropdown_style"
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      memoDropdown = (
        <div className="row mt-5 ">
          <div className="col-md-4">
            <h3 className="insight-memo-title">Memo Warehouse</h3>
          </div>
          <div className="col-md-8 memoWarehouse">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-3">
                <select
                  name="memo"
                  className="form-control dropdown_style"
                  onChange={onSelectMemoOption}
                >
                  <option value="Memo">Memo</option>
                  <option value="mSight">mSight</option>
                  <option value="mCards">mCards</option>
                </select>
              </div>
              <div className="col-3">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control dropdown_style"
                />
              </div>

              <div className="col-1">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <i className="fas fa-star nav_icons"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    deckCartMobile = (<div className="col-md-12 mt-3 deck-cart-mobile">
    <div className="row">
    
    <div className="col-12">
      <div className="row">
        <div className="col-2  text-center">
          <img src={Thumb1} alt="Memosight" className="img-fluid" />
        </div>
        <div className="col-2  text-center">
          <img src={InsightIcon} alt="Memosight" className="img-fluid" />
          <span className="mCard_count">6 Cards</span>
        </div>
        {visibleThumb1 ? (
          <div className="col-2  text-center">
            <img src={Thumb2} alt="Memosight" className="img-fluid" />
          </div>
        ) : (
          ""
        )}
        {visibleThumb2 ? (
          <div className="col-2  text-center">
            <img src={Thumb1} alt="Memosight" className="img-fluid" />
          </div>
        ) : (
          ""
        )}
        {visibleDeck ? (
          <div className="col-2  text-center">
            <img src={InsightIcon} alt="Memosight" className="img-fluid" />
            <span className="mCard_count">6 Cards</span>
          </div>
        ) : (
          ""
        )}
        <div className="col-2"></div>
      </div>
    </div>
    <div className="col-12 text-center mt-2">
      <h6>15 Slides to be Generated</h6>
      
      <button className="btn btn-light btn-block generate_deck" onClick={toggle}>
        <i className="fas fa-cloud-download-alt"></i> Generate mDeck
      </button>
      
    </div>
  </div>
    </div>);


  } else if (memoOption === "mCards") {
    console.log("Selected mCards");

   

    

    if (isMobile) {
      memoDropdown = (
        <div className="row mt-5 ">
          <div className="col-9">
            <spam className="insight-memo-title">Memo Warehouse</spam>
          </div>
          <div className="col-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
              />
              <label className="form-check-label" for="flexSwitchCheckDefault">
                <i className="fas fa-star nav_icons"></i>
              </label>
            </div>
          </div>
          <div className="col-12 memoWarehouse">
            <div className="row">
              <div className="col-4">
                <select
                  name="memo"
                  className="form-control dropdown_style"
                  onChange={onSelectMemoOption}
                >
                  <option value="Memo">Memo</option>
                  <option value="mSight">mSight</option>
                  <option value="mCards">mCards</option>
                </select>
              </div>
              <div className="col-4">
                <select
                  name="memoLabel"
                  className="form-control dropdown_style"
                >
                  <option value="All">All</option>
                  <option value="Campaign">Campaign</option>
                  <option value="Social">Social</option>
                  <option value="Click-Stream">Click-Stream</option>
                </select>
              </div>
              <div className="col-4">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control dropdown_style"
                />
              </div>
            </div>
          </div>
        </div>
      );

      memoOptionWrapper = (
        <SimpleReactLightbox>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD1}
                  imageBig={MobileInsight1}
                  callFunction={VisibleThumb1}
                />
                <MemoCard
                  label="Click-Stream"
                  date="Week of September, 14"
                  image={MCARD2}
                  imageBig={MobileInsight2}
                  callFunction={VisibleThumb2}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD3}
                  imageBig={MobileInsight3}
                  callFunction={VisibleThumb2}
                />
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD4}
                  imageBig={MobileInsight4}
                  callFunction={VisibleThumb1}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD5}
                  imageBig={MobileInsight5}
                  callFunction={VisibleThumb1}
                />
                <MemoCard
                  label="Click-Stream"
                  date="Week of September, 14"
                  image={MCARD6}
                  imageBig={MobileInsight6}
                  callFunction={VisibleThumb2}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD7}
                  imageBig={MobileInsight7}
                  callFunction={VisibleThumb2}
                />
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD2}
                  imageBig={MobileInsight2}
                  callFunction={VisibleThumb1}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD3}
                  imageBig={MobileInsight3}
                  callFunction={VisibleThumb1}
                />
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD4}
                  imageBig={MobileInsight4}
                  callFunction={VisibleThumb2}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD5}
                  imageBig={MobileInsight5}
                  callFunction={VisibleThumb2}
                />
                <MemoCard
                  label="Click-Stream"
                  date="Week of September, 14"
                  image={MCARD6}
                  imageBig={MobileInsight6}
                  callFunction={VisibleThumb1}
                />
              </div>
            </div>
          </div>
        </div>
        </SimpleReactLightbox>
      ); 
    } else {
      memoDropdown = (
        <div className="row mt-5 ">
          <div className="col-md-4">
            <h3 className="insight-memo-title">Memo Warehouse</h3>
          </div>
          <div className="col-md-8 memoWarehouse">
            <div className="row">
              <div className="col-1"></div>
              <div className="col-3">
                <select
                  name="memo"
                  className="form-control dropdown_style"
                  onChange={onSelectMemoOption}
                >
                  <option value="Memo">Memo</option>
                  <option value="mSight">mSight</option>
                  <option value="mCards">mCards</option>
                </select>
              </div>
              <div className="col-3">
                <select
                  name="memoLabel"
                  className="form-control dropdown_style"
                >
                  <option value="All">All</option>
                  <option value="Campaign">Campaign</option>
                  <option value="Social">Social</option>
                  <option value="Click-Stream">Click-Stream</option>
                </select>
              </div>
              <div className="col-3">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control dropdown_style"
                />
              </div>

              <div className="col-1">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <i className="fas fa-star nav_icons"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      memoOptionWrapper = (
        <SimpleReactLightbox>
        <div className="row">
          <div className="col-md-2 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD1}
                  imageBig={Insight1}
                  callFunction={VisibleThumb1}
                />
                <MemoCard
                  label="Click-Stream"
                  date="Week of September, 14"
                  image={MCARD2}
                  imageBig={Insight2}
                  callFunction={VisibleThumb2}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD3}
                  imageBig={Insight3}
                  callFunction={VisibleThumb2}
                />
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD4}
                  imageBig={Insight4}
                  callFunction={VisibleThumb1}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD5}
                  imageBig={Insight5}
                  callFunction={VisibleThumb1}
                />
                <MemoCard
                  label="Click-Stream"
                  date="Week of September, 14"
                  image={MCARD6}
                  imageBig={Insight6}
                  callFunction={VisibleThumb2}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD7}
                  imageBig={Insight7}
                  callFunction={VisibleThumb2}
                />
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD2}
                  imageBig={Insight2}
                  callFunction={VisibleThumb1}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD3}
                  imageBig={Insight3}
                  callFunction={VisibleThumb1}
                />
                <MemoCard
                  label="Social"
                  date="Week of September, 14"
                  image={MCARD4}
                  imageBig={Insight4}
                  callFunction={VisibleThumb2}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <MemoCard
                  label="Campaign"
                  date="Week of September, 14"
                  image={MCARD5}
                  imageBig={Insight5}
                  callFunction={VisibleThumb2}
                />
                <MemoCard
                  label="Click-Stream"
                  date="Week of September, 14"
                  image={MCARD6}
                  imageBig={Insight6}
                  callFunction={VisibleThumb1}
                />
              </div>
            </div>
          </div>
        </div>
        </SimpleReactLightbox>
      ); 
    }
    deckCartMobile=(<div className="col-md-12 mt-3 deck-cart-mobile">
    <div className="row">
    
    <div className="col-12">
      <div className="row">
        <div className="col-2  text-center">
          <img src={Thumb1} alt="Memosight" className="img-fluid" />
        </div>
        <div className="col-2  text-center">
          <img src={InsightIcon} alt="Memosight" className="img-fluid" />
          <span className="mCard_count">6 Cards</span>
        </div>
        {visibleThumb1 ? (
          <div className="col-2  text-center">
            <img src={Thumb2} alt="Memosight" className="img-fluid" />
          </div>
        ) : (
          ""
        )}
        {visibleThumb2 ? (
          <div className="col-2  text-center">
            <img src={Thumb1} alt="Memosight" className="img-fluid" />
          </div>
        ) : (
          ""
        )}
        {visibleDeck ? (
          <div className="col-2  text-center">
            <img src={InsightIcon} alt="Memosight" className="img-fluid" />
            <span className="mCard_count">6 Cards</span>
          </div>
        ) : (
          ""
        )}
        <div className="col-2"></div>
      </div>
    </div>
    <div className="col-12 text-center mt-2">
      <h6>15 Slides to be Generated</h6>
      <button className="btn btn-light btn-block generate_deck" onClick={toggle}>
        <i className="fas fa-cloud-download-alt"></i> Generate mDeck
      </button>
    </div>
  </div>
    </div>)
  }

  return (
    <div className="container-fluid">
      {menubar}
      <div className="row">
      <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Generate mDeck</ModalHeader>
      <ModalBody>
        mDeck will be dlivered to your registered email
      </ModalBody>
      <ModalFooter>
       
        <Button color="secondary" onClick={toggle}>Okay</Button>
      </ModalFooter>
    </Modal>
        <div className="col-md-12 main-container">
          <Header />

          {memoDropdown}

          <div className="row  ">
            <div className="col-md-12 mt-3 ">{memoOptionWrapper}</div>
            {bottomBar ? bottomDiv : ""}
            {bottomBarMin ? bottomDivMin : ""}
            {deckCartMobile}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
