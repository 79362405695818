import { BrowserRouter as Router, Route} from "react-router-dom";

import { Faq } from "./components/faq/Faq";
import { Home } from "./components/Home/Home";
import { Insight } from "./components/insight/Insight";
import { MemoWareHousing } from "./components/memoWarehousing/MemoWareHousing";
import { Privacy } from "./components/privacyandsecurity/PrivacyAndSecurity";
import { Resources } from "./components/resources/Resources"
import { Support } from "./components/support/Support";
import { Profile } from "./components/profile/Profile";
import { Login } from "./components/login/Login";



function App() {
  return (
    <Router>
    <div>
    <Route exact path="/" component={Login} />     
    <Route exact path="/home" component={Home} />
    <Route exact path="/insight" component={Insight} />
    <Route exact path="/memowarehouse" component={MemoWareHousing} />
    <Route exact path="/resources" component={Resources} />
    <Route exact path="/privacyandsecurity" component={Privacy} />
    <Route exact path="/faq" component={Faq} />
    <Route exact path="/support" component={Support} />
    <Route exact path="/profile" component={Profile} />      

      
    </div>
    </Router>
  );
}

export default App;
