import React from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import LATESTMP3 from '../../assets/audios/Week1.mp3';
import Fullscreen from "../../assets/fullscreen.png";
import Audio from '../audioPlayer/audio1/Audio';

export const MAudio = () => {
    const handle = useFullScreenHandle();

    return (
        <div className="latestAudioWrapper">
                <div className="row">
                <div className="col-md-12">
                <h5 className="audio-title">mAudio</h5>
                </div>
               
            
                <div className="col-9">
                <FullScreen handle={handle}>
                <Audio song={LATESTMP3} />
                </FullScreen>
                </div>
                <div className="col-3 mt-4">
               
              <img src={Fullscreen} alt="" className="memosight-Icons" onClick={handle.enter} />  
                </div>
                
            </div>
        </div>
    )
}
