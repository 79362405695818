import React from "react";
import {Link} from "react-router-dom";
import ClientLogo from "../../assets/makaw-logo.png";
// import UserIcon from "../../assets/User-icon.png";
// import Notification from "../../assets/notification.png";

export const Header = () => {
  return (
    <div className="row ">
      <div className="col-6 ">
        <Link to="/home"><img src={ClientLogo} alt="Nabler" className="clientLogo" /></Link>
      </div>
      <div className="col-6 user_column">
        <span className="user_greeting">Hi, John</span>
        <Link to="/profile"><i className="fas fa-user-circle nav_icons"></i></Link>
        <Link to="/"><i className="far fa-bell nav_icons"></i></Link>
      </div>
    </div>
  );
};
