import React from 'react';
import {Link} from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import InsightIcon from '../../assets/Insights-icon.png';
import Insight1 from '../../assets/Insight1.png';
import Insight2 from '../../assets/insight2.png';

export const MSight = () => {
    return (
        <div className="latestAudioWrapper mt-3">
                <div className="row">
                <div className="col-md-12">
                 <h5 className="audio-title">mSight</h5>
                </div>
            
            
                <div className="col-9">
                

                <Splide
                options={ {
                    rewind : true,
                    width  : 400,
                    gap    : '1rem',
                    
			perPage   : 3
                  } }
                >
                <SplideSlide>
                  <img src={Insight1} alt="" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                <img src={Insight2} alt="" className="img-fluid" />
                </SplideSlide>
                <SplideSlide>
                <img src={Insight1} alt="" className="img-fluid" />
              </SplideSlide>
              <SplideSlide>
              <img src={Insight2} alt="" className="img-fluid" />
              </SplideSlide>
              </Splide>
                </div>
                <div className="col-3 text-center">
                 <Link to="/insight"><img src={InsightIcon} alt="Memosight" className="img-fluid" /></Link>
                </div>
                
            </div>
        </div>
    )
}
