import React from "react";
import { Link } from 'react-router-dom';
import LOGO from '../../assets/Memosight-logo.png';






export const Login = () => {
  
   


   

  return (
    <div className="container-fluid">
    
      <div className="row login-container">
       
        <div className="col-md-12 text-center">
        <main className="form-signin">
        <form>
          <img className="mb-4" src={LOGO} alt="Memosight" />
          <br/>
          <label for="inputEmail" className="visually-hidden">Email address</label>
          <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required autofocus value="john@demo.com"/>
          <label for="inputPassword" className="visually-hidden">Password</label>
          <input type="password" id="inputPassword" className="form-control" placeholder="Password" required value="demo123" />
          <p><Link to="/forgot-password" className="forgot-password">Forgot password?</Link></p>
          <Link className="w-100 btn btn-lg btn-primary" to="/home"><i className="fas fa-sign-in-alt"></i> Sign in</Link>
          
        </form>
      </main>
         
        </div>
      </div>
      <div className="row login_copyright">
      <p className="mt-5 mb-3 text-muted">&copy; Memosight 2021. All rights reserved</p>
      </div>
    </div>
  );
};
