import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";




export const MemoCard = ({label,date,image,imageBig,callFunction}) => {
  
    return (
      
      <div className="row">
        <div className="col-12 ">
        <div className="profile-card-2">
        <SRLWrapper>
        <a href={imageBig}>
        <img src={image} className="img img-responsive" alt="" />
        </a>
        </SRLWrapper>
        <div className="profile-name">{label}</div>
        <div className="profile-username">{date}</div>
        <div className="profile-icons"><button className="btn-plus-mSight" onClick={callFunction}><i className="fas fa-plus"></i></button></div>
         </div>
      </div>
       
      
      </div>
      

    
    )
}
