import React, {useState} from "react";
//import {Redirect} from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { slide as Menu } from "react-burger-menu";
import { MobileMenu } from "../common/MobileMenu";

import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";
import AVATAR from "../../assets/avatar.png";
import LOGOUT from "../../assets/logout.png";
import RESET from "../../assets/reset.png";
import UPLOAD from "../../assets/upload.png";

export const Profile = () => {

  const [lightMode, setLightMode] = useState(false);

  const LightMode = (e) => {

    // if (e.target.value) {
      setLightMode(!lightMode);
    // }

    console.log(lightMode)
    
  }


  let menubar;

  if (isMobile) {
    menubar = (
      <Menu width={200} customBurgerIcon={<i className="fas fa-bars"></i>}>
        <MobileMenu />
      </Menu>
    );
  } else {
    menubar = <SideNavBar />;
  }

  if (lightMode) {
    window.location.href = "https://memosight-frontend.vercel.app/profile";
  } else {

  }
  

  return (
    <div className="container-fluid">
      {menubar}
      <div className="row">
        <div className="col-md-12 main-container">
          <Header />
          <div className="row mt-5 profileWrapper">
            <div className="col-md-6">
              <h3 className="insight-memo-title">Profile</h3>
            </div>
            <div className="col-md-6 insightIconsWrapper"></div>
            <div className="col-md-12 mt-3 ">
              <div className="row profile_wrapper">
                <div className="col-md-2 col-sm-12 text-center avatar-wrapper">
                  
                  
                   <div className="row profile_wrapper">
                     <div className="col-12">
                     <img src={AVATAR} alt="" className="avatar" />
                     </div>
                     <div className="col-12 upload">
                     <img src={UPLOAD} alt="" className="" />
                     </div>
                   </div>
                </div>
                <div className="col-md-10 col-sm-12">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value="John"
                        className="form-control profileInput"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value="Doe"
                        className="form-control profileInput"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <label>Email</label>
                      <input
                        type="text"
                        name="email"
                        value="john@demo.com"
                        className="form-control profileInput"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12 mt-3">
                      <label>Role</label>
                      <input
                        type="text"
                        name="role"
                        value="Manager"
                        className="form-control profileInput"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12 mt-3">
                      <label>Department</label>
                      <input
                        type="text"
                        name="firstName"
                        value="Marketing"
                        className="form-control profileInput"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-3">
                      <label>Company</label>
                      <input
                        type="text"
                        name="company"
                        value="ABC Group"
                        className="form-control profileInput"
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 mt-3 update_button">
                    <button
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    UPDATE
                  </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row profile_wrapper mt-3">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      
                      <div className="form-check form-switch">
                      <label>Push Notification</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          checked
                        />
                        
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      
                      <div className="form-check form-switch">
                      <label>Email Notification</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          checked
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      
                      <div className="form-check form-switch">
                      <label>Light Mode</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          checked={lightMode}
                          onChange={LightMode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row profile_wrapper mt-3 mb-5">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-6 logoutAlign">
                      <button
                        type="button"
                        className="btn btn-dark btn-sm profileBtn"
                      >
                      <img src={LOGOUT} alt="" className="logout" /> Logout
                      </button>
                      </div>
                      <div className="col-6 resetAlign">
                      <button
                        type="button"
                        className="btn btn-dark btn-sm profileBtn"
                      >
                      <img src={RESET} alt="" className="reset" /> Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
