import React from "react";
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';
import { MobileMenu } from "../common/MobileMenu";

import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { SideNavBar } from "../common/SideNavBar";

import ResourceActive from "../../assets/Resources_active.png";


export const Resources = () => {
  
  let menubar;
 

  if (isMobile) {
    
    menubar = <Menu width={200 } customBurgerIcon={ <i className="fas fa-bars"></i> }>
       <MobileMenu />
    </Menu>
  
 
  } else {
   
    menubar = <SideNavBar currentPage="resourses" />
  }


   

  return (
    <div className="container-fluid">
    {menubar}
      <div className="row">
       
        <div className="col-md-12 main-container">
          <Header />
          <div className="row mt-5 ">
            <div className="col-md-6">
              <h3 className="insight-memo-title"><img src={ResourceActive} alt="" /> Resources</h3>
            </div>
            <div className="col-md-6 insightIconsWrapper">
              
            </div>
            <div className="col-md-12 mt-3 mb-5 profile_wrapper">
              <div className="row p-3">
                 <div className="col-md-4 col-sm-12 ">
                   <h4>Introduction</h4>
                   <iframe title="Introduction" src="https://player.vimeo.com/video/515714321" width="100%" height="250" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

                 </div>
                 <div className="col-md-4 col-sm-12 ">
                    <h4>How Memosight works ?</h4>
                    <iframe title="How Memosight works ?" src="https://player.vimeo.com/video/515714321" width="100%" height="250" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                 </div>
                 <div className="col-md-4 col-sm-12 ">
                   <h4>How to use Memosight ?</h4>
                   <iframe title="How to use Memosight ?" src="https://player.vimeo.com/video/515714321" width="100%" height="250" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                 </div>
              </div>
              
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
